/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {CLOUD_FUNCTION_API_BASE_URL} from '../config/api-config';

const Index = () => {
    const [guides, setGuides] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {}, []);

    useEffect(() => {
        getGuides();
    }, [page]);

    const getGuides = async () => {
        try {
            const res = await axios.get(
                `${CLOUD_FUNCTION_API_BASE_URL}/guides?page=${page}`,
            );
            const responseData = res.data;
            const data = responseData.data;

            setGuides(data.data);
            setPage(data.page);
            setTotal(data.total);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.log(error);
        }
    };

    const onChangePage = (e, newPage) => {
        e.preventDefault();
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    return (
        <>
            <Header totalGuides={total} />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Guide List</h3>
                            </CardHeader>
                            <Table
                                className="align-items-center table-flush"
                                responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Phone Number</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Country</th>
                                        <th scope="col">Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {guides.map((v, i) => (
                                        <tr key={i}>
                                            <th scope="row">
                                                <Media className="align-items-center">
                                                    <a
                                                        className="avatar rounded-circle mr-3"
                                                        href="#pablo"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }>
                                                        <img
                                                            alt="..."
                                                            style={{
                                                                height: 50,
                                                                width: 50,
                                                                objectFit:
                                                                    'cover',
                                                            }}
                                                            src={v?.photo}
                                                        />
                                                    </a>
                                                    <Media>
                                                        <Link
                                                            className="mb-0 text-sm"
                                                            to={`/admin/guide-detail?uid=${v.uid}`}>
                                                            {v?.full_name}
                                                        </Link>
                                                    </Media>
                                                </Media>
                                            </th>
                                            <td>
                                                <Badge
                                                    color=""
                                                    className="badge-dot mr-4">
                                                    <i
                                                        className={`bg-${
                                                            v.status == 0
                                                                ? 'warning'
                                                                : v.status == 1
                                                                ? 'success'
                                                                : 'danger'
                                                        }`}
                                                    />
                                                    {v.status == 0
                                                        ? 'Pending'
                                                        : v.status == 1
                                                        ? 'Verified'
                                                        : 'Deactivated'}
                                                </Badge>
                                            </td>
                                            <td>{v?.phone}</td>
                                            <td>{v?.email}</td>
                                            <td>{v?.location?.country}</td>
                                            <td>{`${v?.currency} ${v?.fee}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                <nav aria-label="...">
                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0">
                                        <PaginationItem
                                            className={
                                                page == 1 ? 'disabled' : ''
                                            }>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    onChangePage(e, page - 1)
                                                }
                                                tabIndex="-1">
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">
                                                    Previous
                                                </span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        {totalPages != null &&
                                            [...Array(totalPages)].map(
                                                (v, i) => (
                                                    <PaginationItem
                                                        key={i}
                                                        className={
                                                            page == i + 1
                                                                ? 'active'
                                                                : ''
                                                        }>
                                                        <PaginationLink
                                                            href="#pablo"
                                                            onClick={(e) =>
                                                                onChangePage(
                                                                    e,
                                                                    i + 1,
                                                                )
                                                            }>
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                ),
                                            )}
                                        <PaginationItem
                                            className={
                                                page == totalPages
                                                    ? 'disabled'
                                                    : ''
                                            }>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) =>
                                                    onChangePage(e, page + 1)
                                                }>
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">
                                                    Next
                                                </span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Index;
