/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Badge,
    Alert
} from 'reactstrap';
// core components
import UserHeader from 'components/Headers/UserHeader.js';
import {useHistory, useLocation} from 'react-router-dom';
import {useState, useEffect} from 'react';
import axios from 'axios';
import {CLOUD_FUNCTION_API_BASE_URL} from 'config/api-config';

const Profile = () => {
    const history = useHistory();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [guide, setGuide] = useState(null);
    const uid = new URLSearchParams(location.search).get('uid');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [verifyErrorMessage, setVerifyErrorMessage] = useState('');

    useEffect(() => {
        getGuideDetail();
    }, []);

    const getGuideDetail = async () => {
        try {
            const res = await axios.get(
                `${CLOUD_FUNCTION_API_BASE_URL}/guides/${uid}`,
            );
            setGuide(res.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    const onVerify = async () => {
        setVerifyErrorMessage('');

        if(password == ''){
            setVerifyErrorMessage('Password is required');
            return;
        }
        if(password.length < 6){
            setVerifyErrorMessage('Minimum password characters is 6');
            return;
        }
        if(password != confirmPassword){
            setVerifyErrorMessage('Confirm password is not correct');
            return;
        }

        setIsLoading(true);
        try {
            const res = await axios.post(
                `${CLOUD_FUNCTION_API_BASE_URL}/guides/${uid}/verify`,
                {password: password},
            );

            setIsLoading(false);
            window.top.location.href = '/admin/index';
        } catch (error) {
            if(error?.response?.data?.message != ''){
                setVerifyErrorMessage(error.response.data.message);
            }else {
                setVerifyErrorMessage(error.message);
            }
            setIsLoading(false);
        }
    };

    return (
        <>
            <UserHeader />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                        <Card className="card-profile shadow">
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">
                                        <a
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}>
                                            {guide && (
                                                <img
                                                    alt="..."
                                                    className="rounded-circle"
                                                    style={{
                                                        height: 170,
                                                        width: 170,
                                                        objectFit: 'cover',
                                                    }}
                                                    src={guide?.photo}
                                                />
                                            )}
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between"></div>
                            </CardHeader>
                            <CardBody className="pt-2 pt-md-6">
                                <Row>
                                    <div className="col">
                                        <div className="card-profile-stats d-flex justify-content-center mt-md-2">
                                            <div className="text-center">
                                                <h3>{guide?.full_name}</h3>
                                                <div className="h5 font-weight-300">
                                                    {guide?.location?.country}
                                                </div>
                                                {guide && (
                                                    <Badge
                                                        color=""
                                                        className="badge-dot">
                                                        <i
                                                            className={`bg-${
                                                                guide.status ==
                                                                0
                                                                    ? 'warning'
                                                                    : guide.status ==
                                                                      1
                                                                    ? 'success'
                                                                    : 'danger'
                                                            }`}
                                                        />
                                                        {guide.status == 0
                                                            ? 'Pending'
                                                            : guide.status == 1
                                                            ? 'Verified'
                                                            : 'Deactivated'}
                                                    </Badge>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Guide Detail</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        User information
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-full-name">
                                                        Full Name
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-full-name"
                                                        type="text"
                                                        value={guide?.full_name}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name">
                                                        Date of Birth
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-first-name"
                                                        placeholder="First name"
                                                        type="text"
                                                        value={
                                                            guide?.dob.substring(0, 10)
                                                        }
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name">
                                                        First name
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-first-name"
                                                        placeholder="First name"
                                                        type="text"
                                                        value={
                                                            guide?.first_name
                                                        }
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-last-name">
                                                        Last name
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-last-name"
                                                        placeholder="Last name"
                                                        type="text"
                                                        value={guide?.last_name}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label">
                                                        Language
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        value={
                                                            guide?.languages
                                                        }
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label">
                                                        Services
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        value={guide?.services}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label">
                                                        Fee
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        value={
                                                            `${guide?.currency} ${guide?.fee}`
                                                        }
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label">
                                                        Registered At
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        value={guide?.created_at}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    {/* Address */}
                                    <h6 className="heading-small text-muted mb-4">
                                        Contact information
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label">
                                                        Phone Number
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        value={
                                                            guide?.phone
                                                        }
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label">
                                                        Email
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        value={guide?.email}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city">
                                                        City
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-city"
                                                        placeholder="City"
                                                        type="text"
                                                        value={
                                                            guide?.location
                                                                ?.city
                                                        }
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country">
                                                        Country
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-country"
                                                        placeholder="Country"
                                                        type="text"
                                                        value={
                                                            guide?.location
                                                                ?.country
                                                        }
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    {/* Description */}
                                    <h6 className="heading-small text-muted mb-4">
                                        About me
                                    </h6>
                                    <div className="pl-lg-4">
                                        <FormGroup>
                                            <label>About Me</label>
                                            <Input
                                                className="form-control-alternative"
                                                rows="4"
                                                type="textarea"
                                                value={guide?.about}
                                                disabled
                                            />
                                        </FormGroup>
                                    </div>
                                    {
                                        guide?.status == 0 &&
                                        <>
                                            <hr className="my-4" />
                                            <h6 className="heading-small text-muted mb-4">
                                                Password
                                            </h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-password">
                                                                Password
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id='input-password'
                                                                type="password"
                                                                value={password}
                                                                defaultValue={''}
                                                                onChange={e => setPassword(e.target.value)}
                                                                placeholder={'Enter first guide password'}
                                                                disabled={isLoading}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor='input-confirm-password'>
                                                                Confirm Password
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id='input-confirm-password'
                                                                type="password"
                                                                value={confirmPassword}
                                                                defaultValue={''}
                                                                onChange={e => setConfirmPassword(e.target.value)}
                                                                placeholder={'Retype password'}
                                                                disabled={isLoading}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                    {
                                        verifyErrorMessage != '' &&
                                        <Alert color="danger">
                                            <strong>Failed!</strong> {verifyErrorMessage}
                                        </Alert>
                                    }
                                    <Row className="align-items-center">
                                        <Col className="text-right">
                                            <Button
                                                color="warning"
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    history.replace(
                                                        '/admin/index',
                                                    );
                                                }}
                                                disabled={isLoading}>
                                                Back
                                            </Button>
                                            {guide?.status == 0 && (
                                                <Button
                                                    color="success"
                                                    type="button"
                                                    onClick={onVerify}
                                                    disabled={isLoading}>
                                                    Verify
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Profile;
