/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from 'views/Index.js';
import Guide from 'views/Guide.js';
import Login from 'views/Login.js';

var routes = [
    {
        path: '/index',
        name: 'Dashboard',
        icon: 'ni ni-tv-2 text-primary',
        component: Index,
        layout: '/admin',
    },
    {
        path: '/guide-detail',
        name: 'Guide Detail',
        icon: 'ni ni-tv-2 text-primary',
        component: Guide,
        layout: '/admin',
        invisible: true,
    },
    {
        path: '/login',
        name: 'Login',
        icon: 'ni ni-key-25 text-info',
        component: Login,
        layout: '/auth',
        invisible: true,
    },
];
export default routes;
