export const getCurrentUser = () => {
    const userJson = localStorage.getItem('userJson');
    const user = userJson ? JSON.parse(userJson) : null;

    return user;
}

export const setCurrentUser = (user) => {
    if (user){
        localStorage.setItem('userJson', JSON.stringify(user));
    }
}

export const logout = () => {
    localStorage.removeItem('userJson');
    window.top.location.href = '/auth/login';
}